/* eslint-disable react/forbid-dom-props */
import { Dialog } from '@headlessui/react';
import React, { useState } from 'react';
import { usePopper } from 'react-popper';
import AccountMenuContent from './MenuBar/AccountMenuContent';

export default function AccountMenu({
  setOpen,
  open,
  referenceElement,
}: {
  setOpen: (state: boolean) => void;
  open: boolean;
  referenceElement: any;
}) {
  const [popperElement, setPopperElement] = useState<any>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
  });

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className="hidden lg:block"
    >
      <Dialog.Panel
        className="z-50 w-52 border border-gray-200 bg-white"
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <AccountMenuContent closeModal={() => setOpen(false)} />
      </Dialog.Panel>
    </Dialog>
  );
}
